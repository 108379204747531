import React from "react"
import classNames from "classnames"
import { SectionSplitProps } from "utils/SectionProps"
import SectionHeader from "components/layout/partials/SectionHeader"
import Image from "components/elements/Image"

import wgen_image from "assets/images/hair_1.png"
import wgen_init_image from "assets/images/wgen_init.png"
import scenelib_image from "assets/images/light_2.png"
import shading_image from "assets/images/shading.png"
import usd_editing_image from "assets/images/usd_editing.png"
import wgen_variants_image from "assets/images/hair_variants.png"

import LayoutDefault from "layouts/LayoutDefault"
import ModalVideo from "components/elements/ModalVideo"

import ButtonGroup from "components/elements/ButtonGroup"
import RequestAccessButton from "components/layout/RequestAccessButton"

import SolutionFeaturesTiles from "components/layout/en/SolutionFeaturesTiles"
import Cta from "components/layout/en/Cta"

const propTypes = {
  ...SectionSplitProps.types,
}

const defaultProps = {
  ...SectionSplitProps.defaults,
}

const Index = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  )

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  )

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  )

  const sectionHeader = {
    title: [
      <>
       Wizart <span className="text-color-primary">DCC</span> Platform <br/>
       for <span className="text-color-primary">Hair</span>
      </>
    ],
    paragraph:
      <> future-proof node-based hair system <span className="text-color-primary">everywhere</span> in the pipeline </>
  }

  return (
    <LayoutDefault {...props}>
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
          <SectionHeader
              data={sectionHeader}
              className="center-content"
              style={{
                paddingBottom: 32,
              }}
            />
            <div className="center-content">
              <ButtonGroup>
                <RequestAccessButton name={"Request Access"} />
              </ButtonGroup>
            </div>
            <div className={splitClasses}>
            <SolutionFeaturesTiles/>
             
            <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  </div>
                  <h3 className="mt-0 mb-12">USD Editing</h3>
                  <p className="m-0">
                  Wizart DCC Platform is a direct <a href="https://graphics.pixar.com/usd">Pixar's Universal Scene Description (USD) </a> editor that gives you a future-proof data format for all your pipeline data compatible with many third-party applications. If this is not enough, you can also export your data to <a href="https://www.alembic.io/">Alembic</a>.
                  Using <b>Hydra</b> as a native viewport and <b>Hydra Render Delegates</b> ecosystem, you can preview your avatars using the majority of renderers on the market.
  
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="-UeXXgjN7yY"
                    width={528}
                    height={396}
                    thumbnailSrc = {usd_editing_image}
                  />
                </div>
              </div>

            <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Open Source Hair System
                  </div>
                  <h3 className="mt-0 mb-12">WGEN</h3>
                  <p className="m-0">
                  Wgen is node-based hair system with support of industry standard <a href="https://github.com/AcademySoftwareFoundation/OpenShadingLanguage"> OSL </a>. OSL networks allow you procedurally change curves shape or change hair color using textures.     
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="E6s1EghFxpY"
                    width={528}
                    height={396}
                    thumbnailSrc = {wgen_init_image}
                  />
                </div>
              </div>

              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  </div>
                  <h3 className="mt-0 mb-12">Sculpting</h3>
                  <p className="m-0">
                   Using the out-the-box toolset for hair sculpting, an artist can create haircut for any of your characters.       
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="k6FaTvMmPYM"
                    width={528}
                    height={396}
                    thumbnailSrc = {wgen_image}
                  />
                </div>
              </div>


              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  </div>
                  <h3 className="mt-0 mb-12">USD integration</h3>
                  <p className="m-0">
                   Wgen nodegraph is fully integrated into USD and Wizart DCC. This way you can use USD rich scene composition features, for example, to setup hair rig switching USD Variants.     
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                   <ModalVideo
                    videoId="GVm_RDC9Mj0"
                    width={528}
                    height={396}
                    thumbnailSrc={wgen_variants_image}
                  />
                </div>
              </div>


              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12">Shading</h3>
                  <p className="m-0">
                  Node Editor allows you to modify any USDShade shading networks for any target renderer. Its important to author shading in same environment with hair grooming tools, because often this workflows are inseparatable.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="qDqClwhsQU4"
                    width={528}
                    height={396}
                    thumbnailSrc={shading_image}
                  />
                </div>
              </div>


              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    In development
                  </div>
                  <h3 className="mt-0 mb-12">Lighting Integration</h3>
                  <p className="m-0">
                   Thanks to  USD/Hydra integration and open-source engine, Wgen could be easily used with any workflow or pipeline.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                 <ModalVideo
                    videoId="jzzCV-r927U"
                    width={528}
                    height={396}
                    thumbnailSrc={scenelib_image}
                  />
                </div>
              </div>




            </div>
          </div>
        </div>
      </section>
      <Cta split topDivider />
    </LayoutDefault>
  )
}

Index.propTypes = propTypes
Index.defaultProps = defaultProps

export default Index